import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled, alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Divider from "@mui/material/Divider";
import { isMobile } from "react-device-detect";

import { doc, updateDoc } from "firebase/firestore";

import HeroCard from "../HeroCard";
import ModalSpot from "../../components/ModalSpot";

import mockData from "../../../data/mockData";
import "./index.scss";
import { updateLobby } from "../../../store/actions/thunks";
import db from "../../../firebase";

import { useMedia } from "../../../utils/use-media";
import {
  connectWallet,
  fetchEthereum,
  fetchAccount,
  addDisconnectHandler,
  addConnectHandler,
  tokenMethods,
} from "../../../browser/web3/meditate";
const lobbiesMock = [
  { lounge: "Lobby", gameMode: "1" },
  { lounge: "Taurus", gameMode: "1" },
  { lounge: "Gemini", gameMode: "1" },
  { lounge: "Cancer", gameMode: 1 },
  { lounge: "Leo", gameMode: "1" },
  { lounge: "Virgo", gameMode: "1" },
  { lounge: "Libra", gameMode: 1 },
  { lounge: "Scorpio", gameMode: 1 },
  { lounge: " Sagittarius ", gameMode: 1 },
  { lounge: "Capricorn", gameMode: 1 },
  { lounge: "Aquarius", gameMode: 1 },
  { lounge: "Pisces", gameMode: 1 },
  { lounge: "Earth", gameMode: 1 },
  { lounge: "Sun", gameMode: 1 },
  { lounge: "Moon", gameMode: 1 },
  { lounge: "Jupiter", gameMode: 1 },
  { lounge: "Neptune", gameMode: 1 },
  { lounge: "Mars", gameMode: 1 },
  { lounge: "Saturn", gameMode: 1 },
  { lounge: "Venus", gameMode: 1 },
  { lounge: "Pluto" },
  { lounge: "Aries" },
  { lounge: "Heart" },
  { lounge: "Soul" },
  { lounge: "Past" },
  { lounge: "Present" },
  { lounge: "Future" },
  { lounge: "Peace" },
  { lounge: "Love" },
  { lounge: "Trust" },
  { lounge: "Namaste" },
  { lounge: "Yoga" },
  { lounge: "Hope" },
  { lounge: "Aura" },
  { lounge: "Chakra" },
  { lounge: "Astral" },
  { lounge: "Tranquil" },
  { lounge: "Relax" },
  { lounge: "Present" },
  { lounge: "Joy" },
];
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default function ResponsiveGrid() {
  const [accountBalance, setAccountBalance] = React.useState(0);

  const mobile = useMedia("(max-width: 580px)");
  const lobbyState = useSelector((state) => state.lobby);
  const audioState = useSelector((state) => state.audio);
  const hotCollectionState = useSelector((state) => state.hotCollection);
  const { users, hotCollections } = hotCollectionState;
  let noSpotUsers = users.filter(function (ele) {
    // console.log("ele?.spot?.lobby", typeof ele?.spot?.lobby);
    return typeof ele?.spot?.lobby === "undefined";
  });
  let spotOwnUsers = users.filter(function (ele) {
    return ele?.spot?.lobby?.length > 0;
  });
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [selectedIndex, setIndex] = React.useState(-1);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (lobby, idx) => {
    dispatch(updateLobby({ type: lobby.lounge, lobbyIndex: idx }));
    setAnchorEl(null);
  };
  const takeSpot = async (index) => {
    setModal(false);

    if (audioState.address)
      await updateDoc(doc(db, "users", audioState.address), {
        spot: { lobby: lobbyState.lobbyType, spotIndex: selectedIndex },
      });
  };
  let sum = 0;
  return (
    <div className={true ? "nft-container-mobile" : "nft-container"}>
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          color: "aliceblue",
          cursor: "pointer",
          marginBottom: 6,
        }}
      >
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {lobbyState.lobbyType ? lobbyState.lobbyType : "Lobby"}
        </Button>
      </div>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <div style={{ height: 450,  paddingInline:10 }}>
          {Array.from({ length: 20 }).map((_, index) => (
            <div
              style={{
                width:200,
                display: "flex",
                justifyContent: "space-between",
              }}
              key={"lobby" + index.toString()}
            >
              <div
                onClick={() => handleClose(lobbiesMock[index], index)}
                style={{
                  flex: 1,
                  borderRight: "1px solid",
                  color:
                    lobbyState.lobbyType === lobbiesMock[index].lounge
                      ? "red"
                      : "black",
                  justifyContent: "center",
                  display: "flex",
                  paddingBottom: 3,
                  cursor: "pointer",
                }}
              >
                {lobbiesMock[index].lounge}
              </div>
              <Divider orientation="vertical" flexItem />
              <div
                onClick={() => handleClose(lobbiesMock[index + 20], index + 20)}
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  color:
                    lobbyState.lobbyType === lobbiesMock[index + 20].lounge
                      ? "red"
                      : "black",
                  cursor: "pointer",
                }}
              >
                {lobbiesMock[index + 20].lounge}
              </div>
            </div>
          ))}
        </div>
      </StyledMenu>
      <Box sx={{ flexGrow: 1, paddingBottom: isMobile ? "20%" : "25%" }}>
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 3, sm: 8, md: 15 }}
        >
          {Array.from(Array(25)).map((_, index) => {
            let ownerIndex = spotOwnUsers.findIndex(
              (element) =>
                element.spot.lobby === lobbyState.lobbyType &&
                element.spot.spotIndex === index
            );
            if (ownerIndex > -1) ++sum;
            return (
              <Grid item xs={1} sm={2} md={3} key={"grid" + index.toString()}>
                <HeroCard
                  data={
                    ownerIndex > -1
                      ? spotOwnUsers[ownerIndex]
                      : noSpotUsers?.length >
                        index + lobbyState.lobbyIndex * 25 - sum
                      ? noSpotUsers[index]
                      : mockData[0]
                  }
                  key={mockData[0].id + index.toString()}
                  id={index}
                  openModal={() => {
                    if (audioState.address && hotCollections.data?.length > 0) {
                      setIndex(index);
                      setModal(true);
                    }
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <ModalSpot
        title={"Would you like to take this spot?"}
        active={modal}
        connectWallet={(isMetamask) => {}}
        close={() => setModal(false)}
        takeSpot={() => takeSpot()}
      />
    </div>
  );
}
