import React, { Component } from "react";

const colors = ["black", "red", "green", "pink", "orange"];
class RadioButtonGroup extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  onValueChange(event) {
    this.props.handleUpdate(event.target.value);
    this.setState({
      selectedOption: event.target.value,
    });
  }

  formSubmit(event) {
    event.preventDefault();
    console.log(this.state.selectedOption);
  }

  render() {
    return (
      <form
        onSubmit={this.formSubmit}
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "space-between",
        }}
      >
        {colors.map((eColor, index) => {
          let labelColor = eColor === 'black' && this.props.darkMode? 'white': eColor;
          return (
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value={eColor}
                  checked={this.state.selectedOption === eColor}
                  onChange={this.onValueChange}
                  style={{marginRight:5, marginLeft:5}}
                />
                {labelColor.charAt(0).toUpperCase() + labelColor.slice(1)}
              </label>
            </div>
          );
        })}
      </form>
    );
  }
}

export default RadioButtonGroup;
