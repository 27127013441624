import "./nav.scss";
import React, { useState } from "react";
import { HeaderNav } from "./data/MainNav";
import { Link } from "@reach/router";

//@ts-ignore
// import Toggle from '@/svg/icons/toggle.inline.svg'
import Toggle from "../../svg/icons/toggle.inline.svg";

export const NavLink = (props) => {
  if (!props.url) {
    return <span className="nav-link">{props.title}</span>;
  }
  if (
    (props.url && props.url.startsWith("http")) ||
    (props.url && props.url.endsWith(".pdf"))
  ) {
    return (
      <a
        href={props.url?.toString()}
        title={props.title}
        rel="follow noopner"
        target="_blank"
        className="nav-link"
      >
        {props.title}
      </a>
    );
  }
  return (
    <Link
      to={props.url?.toString()}
      onClick={() => {
        props.handleDrawerClose();
      }}
      className="nav-link"
    >
      {props.title}
    </Link>
  );
  // return (
  //   <a
  //     href={props.url?.toString()}
  //     title={props.title}
  //     rel="follow"
  //     className="nav-link"
  //     target=''
  //   >
  //     {props.title}
  //   </a>
  // );
};

export const NavItem = (props) => {
  const [toggleMenu, setToggleMenu] = useState(-1);
  if (props.submenu && props.submenu.length) {
    let i = props.index ? props.index : 9999;
    return (
      <li
        key={props.index}
        className={`nav-item has-children ${
          toggleMenu === props.index && "is-active"
        }`}
      >
        <NavLink
          url={props.url}
          title={props.title}
          handleDrawerClose={props.handleDrawerClose}
        />
        <span
          className="toggle"
          onClick={() => setToggleMenu(toggleMenu === i ? -1 : i)}
        >
          <img src={Toggle} className="App-logo" alt="logo" />
        </span>
        <SubNavItem links={props.submenu} />
      </li>
    );
  }

  return (
    <li key={props.index} className="nav-item">
      <NavLink
        url={props.url}
        title={props.title}
        handleDrawerClose={props.handleDrawerClose}
        openAudioModal={props.openAudioModal}
      />
    </li>
  );
};

export const SubNavItem = (props) => {
  return (
    <div className="submenu">
      <ul className="submenu-navigation">
        {props.links.map((link, i) => {
          if (link.submenu) {
            return (
              <li key={i} className={`nav-item has-children is-active`}>
                <NavLink url={link.url} title={link.title} />
                <SubNavItem links={link.submenu} />
              </li>
            );
          }
          return (
            <li key={i} className="nav-item">
              <NavLink url={link.url} title={link.title} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default function Nav(props) {
  return (
    <nav className="nav" data-surface-type="Header Nav">
      <div className="nav-menu">
        {HeaderNav.map((link: any, i: number) => {
          return (
            <NavItem
              key={i}
              title={link.title}
              url={link.url}
              index={i + 1}
              submenu={link.submenu || false}
              handleDrawerClose={props.handleDrawerClose}
              openAudioModal={props.openAudioModal}
            />
          );
        })}
      </div>
    </nav>
  );
}
