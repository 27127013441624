import { getType } from "typesafe-actions";
import * as actions from "../actions";
import {
  initEntityState,
  entityLoadingStarted,
  entityLoadingSucceeded,
  entityLoadingFailed,
} from "../utils";

export const defaultState = {
  hotCollections: initEntityState([]),
  users: [],
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    case getType(actions.getHotCollections.request):
      return {
        ...state,
        hotCollections: entityLoadingStarted(
          state.hotCollections,
          action.payload
        ),
      };
    case getType(actions.getHotCollections.success):
      let nftCollection = entityLoadingSucceeded(state.hotCollections, action.payload);
      console.log('data-', nftCollection?.data?.length);
      localStorage.setItem("nftCollectionLength", nftCollection?.data?.length);
      return {
        ...state,
        hotCollections: nftCollection
      };
    case getType(actions.getHotCollections.failure):
      return {
        ...state,
        hotCollections: entityLoadingFailed(state.hotCollections),
      };
    case "fetechUser":
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
};

export default states;
