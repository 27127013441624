import audios from "../mockData";
export const AudioActionTypes = {
  updateVote: "updateVote",
  updateCurrentPlay: "updateCurrentPlay",
  setCurrentPlay: "setCurrentPlay",
  setAudioList: "setAudioList",
  updateCurrentPlayStatus: "updateCurrentPlayPaused",
  updateUsername: "updateUsername",
};
const initialState = {
  audioList: [],
  currentAudio: {},
  address: "",
  active: false,
  currentPlayStatus: true,
  currentLocalPlayingId: -1,
  name: "",
  savedData: false,
};

const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case AudioActionTypes.updateVote:
      let list = state.audioList.map((item, index) => {
        if (item.id === action.payload.id) {
          let temp = {
            ...item,
            votes:
              action.payload.type == "increase" ? ++item.votes : --item.votes,
            totalVotes:
              action.payload.type == "increase"
                ? ++item.totalVotes
                : --item.totalVotes,
          };
          return temp;
        } else {
          return item;
        }
      });
      return {
        ...state,
        audioList: list,
      };
    case AudioActionTypes.updateCurrentPlay:
      return {
        ...state,
        currentAudio: action.payload,
      };
    case AudioActionTypes.setCurrentPlay:
      return {
        ...state,
        currentAudio: action.payload,
      };
    case AudioActionTypes.setAudioList:
      return {
        ...state,
        audioList: action.payload,
      };
    case "SET_ADDRESS":
      return {
        ...state,
        address: action.payload.account,
        active: action.payload.active,
      };
    case AudioActionTypes.updateCurrentPlayStatus:
      console.log("action.payload?.playing-", action.payload?.playing);
      return {
        ...state,
        currentPlayStatus: action.payload?.playing,
      };
    case AudioActionTypes.updateUsername:
      return {
        ...state,
        name: action.payload?.name,
      };
    case "currentLocalPlayingId":
      return {
        ...state,
        currentLocalPlayingId: action.payload,
      };
    default:
      return state;
  }
};
export default audioReducer;
