import React, { useEffect, useState, useContext } from "react";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { Link, useLocation } from "@reach/router";
import { useWeb3React } from "@web3-react/core";
import { useSelector, useDispatch } from "react-redux";
import { styled, alpha, useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import Nav from "./nav";
import { createBrowserHistory } from "history";

//
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
//
import { ThemeContext, themes } from "../../contexts/ThemeContext";

import discord from "../../assets/img/discord.svg";
import { useMedia } from "../../utils/use-media";
import { formatNumber } from "../../utils/index";
import "./header.scss";
import {
  injected,
  updateAddress,
  updateAmountOfToken,
  showModal,
  fetchHotCollections,
} from "../../store/actions/thunks";
import Player from "../components/audioPlayer";
import ModalConnectors from "../components/ModalConnectors";
import Address from "../components/etherAddress";

import {
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  collection,
  getDocs,
} from "firebase/firestore";
import db from "../../firebase";
import {
  connectWallet,
  fetchEthereum,
  fetchAccount,
  addDisconnectHandler,
  addConnectHandler,
  tokenMethods,
} from "../../browser/web3/meditate";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => {
  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        // the object returned here is passed to the
        // anchor element's props
        return {
          className: isCurrent ? "active" : "non-active",
        };
      }}
    />
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));
const Header = () => {
  const location = useLocation();
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    console.log("test---", location.pathname);
  }, [location]);
  let ethereum;
  const [account, setAccount] = useState(false);
  const [accountBalance, setAccountBalance] = useState(0);
  async function handleConnect() {
    ethereum = await fetchEthereum();
    if (!ethereum) return;
    handleChange();
    ethereum.on("message", handleChange);
    ethereum.on("connect", handleChange);
    ethereum.on("disconnect", handleChange);
    ethereum.on("accountsChanged", handleChange);
  }

  async function handleChange() {}

  async function handleChange() {
    setAccount(await fetchAccount());
    if (!ethereum) return;

    await tokenMethods.balanceOf(updateAccountBalance);
  }
  const updateAccountBalance = (amount) => {
    console.log("amount", amount);
    let amountOfToken = amount !== "0x" ? formatNumber(amount) : 0;
    setAccountBalance(amountOfToken);
    localStorage.setItem("amountOfToken", amountOfToken);

    dispatch(updateAmountOfToken({ amountOfToken }));
  };

  useEffect(() => {
    addDisconnectHandler(handleChange);
    addConnectHandler(handleConnect);
  }, []);

  const audioState = useSelector((state) => state.audio);
  const modalState = useSelector((state) => state.modal);
  const mobile = useMedia("(max-width: 580px)");
  const { currentAudio } = audioState;
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [modal, setModal] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [openMenu, setOpenMenu] = React.useState(false);

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const connect = async () => {
    console.log(
      " Math.floor(Date.now() / 1000)",
      Math.floor(Date.now() / 1000)
    );
    setModal(true);
  };
  const writeFunc = async (address) => {
    await setDoc(doc(db, "users", address), {
      playing: true,
    });
  };
  useEffect(() => {
    if (account) {
      async function fetchMyAPI() {
        const docRef = doc(db, "transfers", account.toLowerCase());
        const docSnap = await getDoc(docRef);
        const NFTs = [];
        // if (docSnap.exists()) {
        const q = query(collection(docRef, "NFTs"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          NFTs.push({ ...doc.data(), id: doc.id });
        });
        // } else {
        //   // doc.data() will be undefined in this case
        //   console.log("No such document!");
        // }
        dispatch(fetchHotCollections(account.toLowerCase(), NFTs));
      }
      dispatch(updateAddress({ account: account.toLowerCase(), active: true }));
      fetchMyAPI();
      onSnapshot(doc(db, "users", account.toLowerCase()), (doc) => {
        if (typeof doc.data() === "undefined") {
          writeFunc(account.toLowerCase());
        }
        if (typeof doc.data()?.playing == "boolean")
          dispatch({
            type: "updateUsername",
            payload: doc.data(),
          });
        if (doc.data()?.nameColor) {
          dispatch({
            type: "updateNameColor",
            payload: { nameColor: doc.data()?.nameColor },
          });
        }
        if (doc.data()?.userNft?.image_url) {
          dispatch({
            type: "updateNft",
            payload: { nft: doc.data()?.userNft },
          });
        }
      });
    }
  }, [account]);

  const [showmenu, btn_icon] = useState(false);
  const openEmoji = () => {
    localStorage.setItem("address", audioState.address);
    if (audioState?.address)
      window.open(
        "emoji",
        "Emoji",
        "height=642,width=1040,modal=yes,alwaysRaised=yes, top=500, left=500,"
      );
    else connect();
  };
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
      if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  const openAudioModal = () => {
    // dispatch(showModal());ß
  };
  const NavItem = (props) => {
    return (
      <div
        className="navbar-item"
        styles={{
          backgroundColor: "red",
          // themeContext.theme === themes.dark ? "black" : "white",
        }}
      >
        {props.children}
      </div>
    );
  };
  const NavItemText = (props) => {
    return (
      <p
        style={{
          backgroundColor:
            themeContext.theme === themes.dark ? "black" : "white",
        }}
      >
        {props.children}
      </p>
    );
  };
  const displayDesktop = () => {
    return (
      <header
        id="myHeader"
        className="navbar opacity-blue"
        style={{ backgroundColor: mobile && "#4348d4" }}
      >
        <div className="container">
          <div className="w-100-nav">
            <div className="logo px-0">
              <div className="navbar-title">
                <NavLink
                  to="/"
                  style={{ borderBottomWidth: 0 }}
                  state={{ fromFeed: true }}
                >
                  <img
                    src={mobile ? "./img/logo.png" : "./img/logo-lg.png"}
                    className="img-fluid"
                    alt="#"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  };
  const headersData = [
    {
      label: "Welcome",
      href: "/welcome",
    },
    // { label: "Stake $PLE", href: "" },
    {
      label: "Lobby",
      href: "/",
    },
    { label: "OpenSea", href: "https://opensea.io/OnlineMeditate" },
    { label: "Emoji", href: "/emoji" },
    { label: "Settings", href: "/settings" },
  ];

  return (
    <header key={"site-header"} className={"site-header"}>
      {displayDesktop()}
      <ModalConnectors
        active={modal}
        connectWallet={(isMetamask, isCoinbase) => {
          connectWallet(isMetamask, isCoinbase);
        }}
        close={() => setModal(false)}
      />
      <Drawer
        sx={{
          width: "100%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
          },
        }}
        variant="persistent"
        anchor="right"
        open={openDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div className={"menuContainer"}>
          <Nav
            handleDrawerClose={handleDrawerClose}
            openAudioModal={openAudioModal}
          />
        </div>
      </Drawer>
    </header>
  );
};
export default Header;
