export const ModalActionTypes = {
  ShowModal: true,
  HideModal: false,
};

export const showModal = () => {
  return {
    type: ModalActionTypes.ShowModal,
  };
};

export const hideModal = () => {
  return {
    type: ModalActionTypes.HideModal,
  };
};
