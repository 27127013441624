import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAXcRv8VxK8HYtxy-1f1Ar33fFN0EbRpL0",
  authDomain: "onlinemeditate-22a55.firebaseapp.com",
  projectId: "onlinemeditate-22a55",
  storageBucket: "onlinemeditate-22a55.appspot.com",
  messagingSenderId: "536042787457",
  appId: "1:536042787457:web:10307c663cf1d22018b380",
  measurementId: "G-Y77HCK6D25",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default db;
