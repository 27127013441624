
export const HeaderNav = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Welcome",
    url: "/welcome",
  },
  {
    title: "Social Media",
    submenu: [
      {
        title: "Twitter",
        url: "https://twitter.com/meditatenft",
      },
      {
        title: "Discord",
        url: "https://discord.com/invite/hutPaNVDSy",
      },
      {
        title: "Youtube",
        url: "https://www.youtube.com/channel/UCVKbhnEJreScOpHAAgnfQUA",
      },
    ],
  },
  {
    title: "Music",
    url: "/audio",
    rel: "follow",
  },
  {
    title: "OpenSea",
    url: "https://opensea.io/OnlineMeditate",
  },
  {
    title: "Emoji",
    url: "/emoji",
  },
  {
    title: "Settings",
    url: "/settings",
  },
];
