import React, { Component, useState, useEffect } from "react";
import Clock from "../../components/Clock";
import Footer from "../../components/footer";
import CarouselCollection from "../../components/CarouselCollection";

import RadioButtonGroup from "../../components/RadioButtonGroup";
import { createGlobalStyle } from "styled-components";
import {
  collection,
  onSnapshot,
  query,
  addDoc,
  where,
} from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import db from "../../../firebase";
import { updateUserNameColor } from "../../../store/actions/thunks";
import { VaultAPIClient } from "../../../core/axios";
import { useMedia } from "../../../utils/use-media";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const AddNftPage = () => {
  const dispatch = useDispatch();
  const isMobile = useMedia("(max-width: 580px)");
  const audioState = useSelector((state) => state.audio);

  const [wAddress, setWAddress] = useState("");
  const [image_url, setImage] = useState("");
  const [permalink, setPermaLink] = useState("");
  const [username, setUsername] = useState("");
  const [admins, setAdmins] = useState([]);
  const [adminFlag, setFlag] = useState(false);
  useEffect(() => {
    const usersQuery = query(
      collection(db, "users"),
      where("admin", "==", true)
    );
    onSnapshot(usersQuery, (querySnapshot) => {
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push({ ...doc.data()?.userNft, id: doc.id, ...doc.data() });
      });
      console.log("users-", users);
      if (users.filter((e) => e.id === audioState.address).length > 0) {
        setFlag(true);
      } else {
        setFlag(false);
      } // dispatch({ type: "fetechUser", payload: users });
    });
  }, [audioState.address]);

  // useEffect(() => {

  // }, [audioState.address]);
  const onSubmit = async () => {
    try {
      const docRef = await addDoc(
        collection(db, "transfers", wAddress.toLowerCase(), "NFTs"),
        {
          image_url,
          permalink,
        }
      );
      console.log("Document written with ID: ", docRef.id);
      alert("saved NFT succesfuly");
    } catch (error) {
      alert("Error-", error);
    }
  };
  const onChangeWAddress = (e) => {
    setWAddress(e.target.value);
  };
  const onChangeImage = (e) => {
    setImage(e.target.value);
  };
  const onChangePermalink = (e) => {
    setPermaLink(e.target.value);
  };
  return (
    <div>
      <GlobalStyles />
      {!isMobile && (
        <section
          className="jumbotron breadcumb no-bg"
          style={{
            backgroundColor: "rgba(72, 122, 234, 0.7)",
          }}
        >
          <div className="mainbreadcumb" style={{ paddingBottom: 70 }}></div>
        </section>
      )}
      {adminFlag ? (
        <section
          className="container"
          style={{ paddingTop: isMobile ? 120 : 40 }}
        >
          <div className="row">
            <div className="col-lg-7 offset-lg-1 mb-5">
              <form id="form-create-item" className="form-border" action="#">
                <div className="field-set">
                  <div className="spacer-single"></div>
                  <h5>Wallet address</h5>
                  <input
                    // maxLength={14}
                    minLength={4}
                    type="text"
                    name="wAddress"
                    id="wAddress"
                    className="form-control"
                    placeholder=""
                    value={wAddress}
                    onChange={onChangeWAddress}
                  />
                  <h5>Image URL</h5>
                  <input
                    // maxLength={14}
                    minLength={4}
                    type="text"
                    name="image_url"
                    id="image_url"
                    className="form-control"
                    placeholder=""
                    value={image_url}
                    onChange={onChangeImage}
                  />
                  <h5>PermaLink</h5>
                  <input
                    // maxLength={14}
                    minLength={4}
                    type="text"
                    name="item_title"
                    id="item_title"
                    className="form-control"
                    placeholder=""
                    value={permalink}
                    onChange={onChangePermalink}
                  />

                  <div className="spacer-20" />
                  <input
                    type="button"
                    id="submit"
                    className="btn-main"
                    value="Save"
                    onClick={onSubmit}
                  />
                </div>
              </form>
            </div>

            <div className="col-lg-3 col-sm-6 col-xs-12">
              <h5>NFT-Image</h5>
              <div className="nft__item m-0">
                <div className="nft__item_wrap">
                  <span>
                    <img
                      src={image_url ? image_url : ""}
                      id="get_file_2"
                      className="lazy nft__item_preview"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section
        className="container"
        style={{ paddingTop: isMobile ? 120 : 40 }}
      >
        <div>You don't have access</div>
        </section>
      )}
    </div>
  );
};
export default AddNftPage;
