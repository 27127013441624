export const ModalActionTypes = {
  ShowModal: true,
  HideModal: false,
};
const initialState = {
  modal: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ModalActionTypes.ShowModal:
      return {
        ...state,
        modal: true,
      };
    case ModalActionTypes.HideModal:
      return {
        ...state,
        modal: false,
      };
    default:
      return state;
  }
};
export default modalReducer;
