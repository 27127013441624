export const UserActionTypes = {
  updateNft: "updateNft",
  updateNameColor:'updateNameColor',
  updateAmountOfToken:'updateAmountOfToken'
};
export const updateUserNft = (payload) => {
  return {
    type: UserActionTypes.updateNft,
    payload,
  };
};
export const updateUserNameColor = (payload) => {
  return {
    type: UserActionTypes.updateNameColor,
    payload,
  };
};
export const updateAmountOfToken = (payload) => {
  return {
    type: UserActionTypes.updateAmountOfToken,
    payload,
  };
};
