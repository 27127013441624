const audios = {
  Wd7lU6Fpv0hp8KUSAqZF:
    "https://onlinemeditate.com/songs/Angels%20Don't%20Cry%20-%20dksdmusic.wav",

  iESbrFK6wB7D6MF0IGkt:
    "https://onlinemeditate.com/songs/Dark%20Meditation%20-%20Q%20Project%20Audio.wav",
  "KSCkFSQs2dHMB4Nz0emn":
    "https://onlinemeditate.com/songs/Deep%20Eternity%20-%20Christian%20De%20Raco.wav",

  "7gDVfHBkAplPhCWasEPF":
    "https://onlinemeditate.com/songs/Distant%20Dream%20-%20dksdmusic.wav",

  "0Q2H0Kuxh1SwXVholPsY":
    "https://onlinemeditate.com/songs/Fantasy%20Drums%20-%20Christian%20De%20Raco.wav",

  eiJ7Td6x99WkXoX81duy:
    "https://onlinemeditate.com/songs/Gong%20Show%20-%20IMME.wav",

  loS8muA63fIY5J9CVFBo:
    "https://onlinemeditate.com/songs/Grounding%20-%20Remy%20Lights.wav",

  SgyO5OM35woFLev3qCkH:
    "https://onlinemeditate.com/songs/Inner%20Ritual%20-%20Spiral%20Dive.wav",

  BXXD21KsyFAzs770iyeg:
    "https://onlinemeditate.com/songs/Oblivion%20-%20dksdmusic.wav",

  pXkCcojVceMwTbqu30aR:
    "https://onlinemeditate.com/songs/Ocean's%20Serenity%20-%20IMME.wav",

  uLGbBY2tI5jmyaEiZ4OU:
    "https://onlinemeditate.com/songs/Rising%20to%20the%20surface%20-%20Tatorita%20Sax.wav",

  sEUnORslrW3LLYLQ9pAe:
    "https://onlinemeditate.com/songs/Roswell's%20Noise%20-%20Mishouju.wav",

  tHUH2ki4pMeP2UU7zSY5:
    "https://onlinemeditate.com/songs/Second%20Chances%20-%20Equal%20Temper.wav",

  awotmo6KiF3uapPHCIKx:
    "https://onlinemeditate.com/songs/Sounds%20of%20peace%20-%20Christian%20De%20Raco.wav",

  "2PKSQDS4ng1t2UnWf6ld":
    "https://onlinemeditate.com/songs/Space%20-%20IMME.wav",

  omNKZM1iL6fWH80MGuOF:
    "https://onlinemeditate.com/songs/Terminal%20Lucidity%20-%20dksdmusic.wav",

  mcg74GRFlh7VM0fev86i:
    "https://onlinemeditate.com/songs/Tibetan%20Trip%20-%20Mishouju.wav",

  GyB38iml963doSuleyBs:
    "https://onlinemeditate.com/songs/Lost%20Avenue%20-%20IMME.wav",

  "1tGgo7k81NZUcrEkOBHk":
    "https://onlinemeditate.com/songs/Floating%20in%20Space%20-%20Spiral%20Dive.wav",

  bloLX7cLXWPbdnp64C7J:
    "https://onlinemeditate.com/songs/Eliminating%20fear%20-%20Maya.wav",

  gM3aRlSyBx01zWIVN2vw:
    "https://onlinemeditate.com/songs/Healing%20Power%20-%20Christian%20De%20Raco.wav",

  ypeFLvjMYOPBAwYWRENc:
    "https://onlinemeditate.com/songs/Anenome%20-%20Tatorita%20Sax.wav",

  "71kNcov92daZKSzPBvxH":
    "https://onlinemeditate.com/songs/Gothenburg%20-%20Equal%20Temper.wav",

  yuLSi7S0fQBfbIcIuso4:
    "https://onlinemeditate.com/songs/Reverse%20-%20Mishouju.wav",
    'F5IkikdiPFMOR3kGs8SJ':"https://onlinemeditate.com/songs/Morse's%20Mind%20-%20Mishouju.wav"
};

export default audios;
