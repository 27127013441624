export const AudioActionTypes = {
  updateVote: "updateVote",
  updateCurrentPlay: "updateCurrentPlay",
};
export const updateVote = (payload) => {
  return {
    type: AudioActionTypes.updateVote,
    payload,
  };
};
export const updateCurrentPlay = (payload) => {
  return {
    type: AudioActionTypes.updateCurrentPlay,
    payload,
  };
};
