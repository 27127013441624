import BigNumber from "bignumber.js";

export const formatNumber = (balance, decimals = 18) => {
    console.log('balance-', balance)
  const displayBalance = new BigNumber(balance).dividedBy(
    new BigNumber(10).pow(decimals)
  );
  return displayBalance.toNumber();
};

export function cutString(str, start = 4, end = 4) {
  if (str.length > start + end) {
    const a = str.substring(0, start);
    const b = str.substr(str.length - end);
    return `${a}...${b}`;
  }
  return str;
}

export function getMaxUint() {
  return new BigNumber(
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    16
  );
}

export function hexToDec(str) {
  var i,
    j,
    digits = [0],
    carry;
  for (i = 0; i < str.length; i += 1) {
    carry = parseInt(str.charAt(i), 16);
    for (j = 0; j < digits.length; j += 1) {
      digits[j] = digits[j] * 16 + carry;
      carry = (digits[j] / 10) | 0;
      digits[j] %= 10;
    }
    while (carry > 0) {
      digits.push(carry % 10);
      carry = (carry / 10) | 0;
    }
  }
  return digits.reverse().join("");
}
