import React, { useEffect, useState } from "react";
import "./index.scss";

import Close from "../../../assets/img/icons/close.svg";
// import { walletconnect } from '@/browser/web3/WalletConnectors'
import { useMedia } from "../../../utils/use-media";

// use deep link for metamask mobile
//   <a href="https://metamask.app.link/dapp/pletori-ui-build.herokuapp.com/" rel="external" title="open in Metamask app"></a>
export default function ModalConnectors(props) {
  const isMobile = useMedia("(max-width: 580px)");
  return (
    <div
      className={`ple-wallet-select`}
      style={{ display: `${props.active ? "flex" : " none"}` }}
    >
      <div className="Spot-list">
        <span
          className="icon--close"
          aria-label="close"
          onClick={() => props.close(false)}
        >
          <img
            src={Close}
            style={{
              width: "20px",
              backgroundColor: "transparent",
            }}
            alt="discord"
          />
        </span>
        <div>
          <h4>Would you like to delete this NFT?</h4>
        </div>
        <ul className="lists" >
          <li onClick={()=>{props.takeSpot()}}>
            <h5>Yes</h5>
          </li>
          <li onClick={props.close}>
            <h5>No</h5>
          </li>
        </ul>
      </div>
    </div>
  );
}
