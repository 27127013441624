import React, { Component, useState, useEffect } from "react";
import Clock from "../../components/Clock";
import Footer from "../../components/footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RadioButtonGroup from "../../components/RadioButtonGroup";
import ModalDeleteNft from "../../components/ModalDeleteNft";

import { createGlobalStyle } from "styled-components";
import {
  collection,
  onSnapshot,
  query,
  addDoc,
  where,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import db from "../../../firebase";
import { updateUserNameColor } from "../../../store/actions/thunks";
import { VaultAPIClient } from "../../../core/axios";
import { useMedia } from "../../../utils/use-media";
import { settings } from "../../components/constants";
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const DeleteNftPage = () => {
  const dispatch = useDispatch();
  const isMobile = useMedia("(max-width: 580px)");
  const audioState = useSelector((state) => state.audio);

  const [wAddress, setWAddress] = useState("");
  const [image_url, setImage] = useState("");
  const [permalink, setPermaLink] = useState("");
  const [modal, setModal] = useState(false);
  const [nfts, setNFTs] = useState([]);
  const [adminFlag, setFlag] = useState(false);
  const [selectedNft, setSelectedNft] = useState(-1);

  useEffect(() => {
    const usersQuery = query(
      collection(db, "users"),
      where("admin", "==", true)
    );
    onSnapshot(usersQuery, (querySnapshot) => {
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push({ ...doc.data()?.userNft, id: doc.id, ...doc.data() });
      });
      console.log("users-", users);
      if (users.filter((e) => e.id === audioState.address).length > 0) {
        setFlag(true);
      } else {
        setFlag(false);
      } // dispatch({ type: "fetechUser", payload: users });
    });
  }, [audioState.address]);

  // useEffect(() => {

  // }, [audioState.address]);
  const onSubmit = async () => {
    try {
      const docRef = doc(db, "transfers", wAddress.toLowerCase());
      const docSnap = await getDoc(docRef);
      const NFTs = [];
      // if (docSnap.exists()) {
        const q = query(collection(docRef, "NFTs"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          NFTs.push({ ...doc.data(), id: doc.id });
        });
        console.log("NFTs", NFTs);
        setNFTs(NFTs);
      // } else {
      //   // doc.data() will be undefined in this case
      //   alert("Error-", "No such document!");
      //   console.log("No such document!");
      // }
    } catch (error) {
      alert(error);
    }
  };

  const handleDeleteNft = async () => {
    console.log("[ nfts[selectedNft].id]", nfts[selectedNft].id);
    try {
      await deleteDoc(
        doc(
          db,
          "transfers",
          wAddress.toLowerCase(),
          "NFTs",
          nfts[selectedNft].id
        )
      );
      await onSubmit();
      alert("deleted NFT succesfuly");
    } catch (error) {
      alert("Error-", error);
    }
  };
  const onChangeWAddress = (e) => {
    setWAddress(e.target.value);
  };
  const onChangeImage = (e) => {
    setImage(e.target.value);
  };
  const onChangePermalink = (e) => {
    setPermaLink(e.target.value);
  };
  const updateNft = (index) => {
    setSelectedNft(index);
    setModal(true);
  };
  const deleteNft = async (index) => {
    setModal(false);
    handleDeleteNft();
  };
  return (
    <div>
      <GlobalStyles />
      {!isMobile && (
        <section
          className="jumbotron breadcumb no-bg"
          style={{
            backgroundColor: "rgba(72, 122, 234, 0.7)",
          }}
        >
          <div className="mainbreadcumb" style={{ paddingBottom: 70 }}></div>
        </section>
      )}
      {adminFlag ? (
        <section
          className="container"
          style={{ paddingTop: isMobile ? 120 : 40 }}
        >
          <div className="row">
            <div className="col-lg-7 offset-lg-1 mb-5">
              <form id="form-create-item" className="form-border" action="#">
                <div className="field-set">
                  <div className="spacer-single"></div>
                  <h5>Wallet address</h5>
                  <input
                    // maxLength={14}
                    minLength={4}
                    type="text"
                    name="wAddress"
                    id="wAddress"
                    className="form-control"
                    placeholder=""
                    value={wAddress}
                    onChange={onChangeWAddress}
                  />

                  <div className="spacer-20" />
                  <input
                    type="button"
                    id="submit"
                    className="btn-main"
                    value="Search"
                    onClick={onSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="nft-big">
            {nfts.length > 0 ? (
              <Slider {...settings}>
                {nfts?.map((nft, index) => (
                  <div
                    className="nft_pic_wrap"
                    onClick={() => updateNft(index)}
                  >
                    <img
                      src={nft.image_url}
                      className={
                        index === selectedNft
                          ? "item_character_image nft_pic_item"
                          : "item_character_image"
                      }
                      style={{ height: "15vW" }}
                      alt=""
                    />
                  </div>
                ))}
              </Slider>
            ) : null}
          </div>
        </section>
      ) : (
        <section
          className="container"
          style={{ paddingTop: isMobile ? 120 : 40 }}
        >
          <div>You don't have access</div>
        </section>
      )}
      <ModalDeleteNft
        active={modal}
        connectWallet={(isMetamask) => {}}
        close={() => setModal(false)}
        takeSpot={() => deleteNft()}
      />
    </div>
  );
};
export default DeleteNftPage;
