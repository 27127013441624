import React from "react";
import SliderMain from "../components/SliderMain";
import Intro from "../components/Intro";
import Characters from "../components/Characters";
import Features from "../components/Features";
import Roadmap from "../components/Roadmap";
import Footer from "../components/footer";
import AudioPlayer from "../components/audioPlayer";
import AudioList from "../components/AudioList";
import NFTList from "../components/NFTList";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { useMedia } from "../../utils/use-media";

const Audio = () => {
  const { active, account, library, activate, deactivate } = useWeb3React();

  const lobbyState = useSelector((state) => state.lobby);
  const mobile = useMedia("(max-width: 580px)");

  return (
    <div style={{backgroundColor: 'black',  paddingTop:mobile?105: "5%",}}>
      <section className="container no-top no-bottom">
        <AudioList />
      </section>
    </div>
  );
};
export default Audio;
