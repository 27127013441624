export const LobbyActionTypes = {
  updateLobby: "updateLobby",
};

const initialState = {
  lobbyType: "Lobby",
  lobbyIndex: 0,

};

const lobbyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LobbyActionTypes.updateLobby:
      return {
        ...state,
        lobbyType: action.payload.type,
        lobbyIndex: action.payload.lobbyIndex,
      };
    default:
      return state;
  }
};
export default lobbyReducer;
