import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slidermain= () => (
    <Reveal className='onStep' keyframes={fadeIn} delay={900} duration={1500} triggerOnce>
      <img src="./img/misc/orc.png" className="lazy img-fluid orc" alt=""/>
      <img src="./img/misc/human.png" className="lazy img-fluid human" alt=""/>
      <img src="./img/misc/banner-placeholder-full.png" className="lazy img-fluid banner-main" alt=""/>
      <svg xmlns = "http://www.w3.org/2000/svg" viewBox = "0 0 800 600" width = "800" height="600" style = {{width: "100%", height: "100%"}}/>
    </Reveal>
);
export default slidermain;