import React, { useEffect, useState } from "react";
import {
  Router,
  Location,
  Redirect,
  createHistory,
  LocationProvider,
} from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import ScrollToTopBtn from "./menu/ScrollToTop";

import Header from "./menu/header";
import Home from "./pages/musicSoft";

import PolygonNft from "./pages/addNFT";
import DeleteNftPage from "./pages/deleteNft";
import MusicModal from "./components/draggableModal";

import { collection, onSnapshot, query, doc, where } from "firebase/firestore";

import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import db from "../firebase";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
);
function getLibrary(provider) {
  return new Web3(provider);
}
const App = () => {
  const dispatch = useDispatch();
  const history = createHistory(window);

  useEffect(() => {
    const q = query(collection(db, "audiosData", ""));
    onSnapshot(q, (querySnapshot) => {
      const audios = [];
      querySnapshot.forEach((doc) => {
        audios.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: "setAudioList", payload: audios });
    });
 
    onSnapshot(doc(db, "audios", "current-playing"), (doc) => {
      dispatch({ type: "setCurrentPlay", payload: doc.data() });
    });
  }, []);
  return (
    <LocationProvider history={history}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className="wraper">
          {window.location.pathname !== "/emoji" && <Header />}
          <PosedRouter>
            <ScrollTop path="/">
              <Home exact path="/">
                <Redirect to="/home" />
              </Home>
              <PolygonNft path="/add-user-nft" />
              <DeleteNftPage path="/delete-user-nft" />
            </ScrollTop>
          </PosedRouter>
          <ScrollToTopBtn />
        </div>
        <MusicModal />
      </Web3ReactProvider>
    </LocationProvider>
  );
};
export default App;
