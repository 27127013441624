import { Axios, Canceler, OpenSeaClient } from "../../../core/axios";
import * as actions from "../../actions";

export const fetchHotCollections = (address, pNftList) => async (dispatch) => {
  dispatch(actions.getHotCollections.request(Canceler.cancel));
  try {
    const { data } = await OpenSeaClient.get("assets", {
      cancelToken: Canceler.token,
      params: {
        limit: 25,
        offset: 0,
        order_direction: "desc",
        collection: "betaom",
        owner: address,
      },
    });
    const res = await OpenSeaClient.get("assets", {
      cancelToken: Canceler.token,
      params: {
        limit: 25,
        offset: 0,
        order_direction: "desc",
        collection: "chibiom",
        owner: address,
      },
    });
    console.log("assets", data.assets);
    let nftAssets = pNftList.concat(data.assets);
    nftAssets = nftAssets.concat(res.data.assets);
    dispatch(actions.getHotCollections.success(nftAssets));
  } catch (err) {
    console.log("err-", err);
    dispatch(actions.getHotCollections.failure(err));
  }
};

export const fetchEvents = () => async (dispatch) => {
  dispatch(actions.getHotCollections.request(Canceler.cancel));

  try {
    const { data } = await OpenSeaClient.get("events", {
      cancelToken: Canceler.token,
      params: {
        asset_contract_address: "",
        // only_opensea: false,
        // order_direction: 'desc',
        offset: 0,
        limit: 10,
      },
    });
    console.log("assets", data.assets);
    dispatch(actions.getHotCollections.success(data.assets));
  } catch (err) {
    dispatch(actions.getHotCollections.failure(err));
  }
};
