import React, { useEffect, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import { useSelector, useDispatch } from "react-redux";
import "./player.scss";
import { doc, setDoc } from "firebase/firestore";
import db from "../../../firebase";
import audios from "../../../store/mockData";
import { useMedia } from "../../../utils/use-media";
import { updateCurrentPlay } from "../../../store/actions/thunks";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Player = (props) => {
  const mobile = useMedia("(max-width: 580px)");

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { handlePlayAudio, isPlay } = props;
  const player = useRef();
  const audioState = useSelector((state) => state.audio);
  const modalState = useSelector((state) => state.modal);
  const hotCollectionState = useSelector((state) => state.hotCollection);
  const { hotCollections } = hotCollectionState;
  const userState = useSelector((state) => state.user);
  const { amountOfToken } = userState;
  useEffect(() => {
    if (isPlay === false) {
      player.current.audio.current.pause();
    }
    if (
      (props.type === "current-playing" ||
        props.type === "current-playing-homepage") &&
      audioState?.currentAudio?.startTime > 0
    ) {
      let currentAudio = props.item;
      var currentTimeInSeconds = Math.floor(Date.now() / 1000);
      var differentTime = currentTimeInSeconds - currentAudio?.startTime;
      if (differentTime >= 0) {
        player.current.audio.current.currentTime = differentTime;
      } else {
        onNextSong();
      }
    }
  }, [isPlay, audioState?.currentAudio]);
  //auto-play
  useEffect(() => {
    console.log(
      "useEffect-audioState.currentPlayStatus",
      audioState.currentPlayStatus
    );
    if (props.type !== "common") {
      if (audioState.currentPlayStatus) {
        var promise = player.current.audio.current.play();

        if (promise !== undefined) {
          promise
            .then((_) => {
              // Autoplay started!
            })
            .catch((error) => {
              handleOpen();
              // Autoplay was prevented.
              // Show a "Play" button so that user can start playback.
            });
        }
      } else {
        player.current.audio.current.pause();
      }
    }
  }, [audioState.currentPlayStatus]);
  useEffect(() => {
    if (
      props.type === "common" &&
      player.current.audio.current.paused &&
      props.item.id === audioState.currentLocalPlayingId
    ) {
      player.current.audio.current.play();
    }
  }, [audioState.audioList]);
  useEffect(() => {
    if (props.type === "current-playing-homepage") {
      console.log(
        "audioState.currentPlayStatus-",
        audioState.currentPlayStatus
      );
    }
    if (props.type === "current-playing-homepage" && modalState.modal) {
      player.current.audio.current.volume = 0;
      player.current.audio.current.pause();
    }
    if (
      props.type === "current-playing-homepage" &&
      !modalState.modal &&
      audioState.currentPlayStatus
    ) {
      player.current.audio.current.volume = 1;
      let currentAudio = props.item;
      var currentTimeInSeconds = Math.floor(Date.now() / 1000);
      var differentTime = currentTimeInSeconds - currentAudio?.startTime;
      // player.current.audio.current.currentTime = differentTime;

      player.current.audio.current.play();
    }
  }, [modalState.modal]);
  const writeFunc = async (item) => {
    await setDoc(doc(db, "audiosData", item.id), item);
  };
  const onAllow = () => {
    handleClose();
    player.current.audio.current.play();
  };
  const onNextSong = async () => {
    let filteredData = audioState.audioList.filter(
      (item) => item?.votedItems?.length > 0
    );
    let auiosTemp;
    if (filteredData?.length > 0) {
      const temp = audioState.audioList.sort(function (a, b) {
        if (b?.votedItems?.length === a?.votedItems?.length) {
          return b.totalVotes - a.totalVotes;
        } else {
          return b?.votedItems?.length - a?.votedItems?.length;
        }
      });
      auiosTemp = temp[0];
    } else {
      const max = audioState.audioList.length - 1;
      const randomNum = Math.floor(Math.random() * (max + 1));
      auiosTemp = audioState.audioList[randomNum];
    }
    auiosTemp = { ...auiosTemp, startTime: Math.floor(Date.now() / 1000) };
    await setDoc(doc(db, "audios", "current-playing"), auiosTemp);
    audioState.audioList.forEach((item, index) => {
      let temp = {
        ...item,
        votedItems: [],
      };
      writeFunc(temp);
    });
    dispatch(updateCurrentPlay(auiosTemp));
  };
  const onPause = async (modalStatus) => {
    if (props.type === "current-playing-homepage" && !modalState.modal) {
      console.log("onPause-", "onPause");
      dispatch({
        type: "updateCurrentPlayPaused",
        payload: { playing: false },
      });
    } else {
      dispatch({
        type: "currentLocalPlayingId",
        payload:
          audioState.currentLocalPlayingId === props.item.id
            ? -1
            : audioState.currentLocalPlayingId,
      });
    }
  };

  const onPlay = async (e) => {
    console.log("e-", e);
    if (props.type === "current-playing-homepage") {
      console.log("onPlay-", "onPlay");

      let currentAudio = props.item;
      var currentTimeInSeconds = Math.floor(Date.now() / 1000);
      var differentTime = currentTimeInSeconds - currentAudio?.startTime;
      if (differentTime >= 5) {
        console.log("differentTime-", differentTime);
        dispatch({
          type: "updateCurrentPlayPaused",
          payload: { playing: true },
        });
        // player.current.audio.current.currentTime = differentTime;
        // player.current.audio.current.play();
      } else if (!audioState.currentPlayStatus) {
        player.current.audio.current.pause();
      }
    } else {
      dispatch({ type: "currentLocalPlayingId", payload: props.item.id });
    }
    handlePlayAudio();
  };
  const onLoadedData = async () => {
    if (props.type !== "common") {
      let currentAudio = props.item;
      var currentTimeInSeconds = Math.floor(Date.now() / 1000);
      var differentTime = currentTimeInSeconds - currentAudio?.startTime;
      if (differentTime >= 0) {
        player.current.audio.current.currentTime = differentTime;
      }
    }
  };
  return (
    <div className={props.type}>
      <AudioPlayer
        id={"audioPlayer" + props.item.id + props.type}
        loop={false}
        autoPlay={props.type !== "common"}
        muted={props.type === "current-playing-homepage" && modalState.modal}
        header={
          props.type !== "current-playing-homepage" &&
          props.item.title + " - " + props.item.artist
        }
        src={audios[props.item.id]}
        onPlay={() => {}}
        onPause={() => {}}
        layout={
          mobile
            ? "stacked"
            : props.type === "current-playing" ||
              props.type === "current-playing-homepage"
            ? "horizontal-reverse"
            : "horizontal"
        }
        onEnded={
          props.type === "current-playing" ||
          props.type === "current-playing-homepage"
            ? onNextSong
            : null
        }
        onPause={onPause}
        onPlay={(e) => onPlay(e)}
        onLoadedData={() => {
          onLoadedData();
        }}
        ref={player}
        onListen={() => {
          if (
            player?.current?.audio?.current?.currentTime > 180 &&
            props.type === "common" &&
            hotCollections.data?.length < 1 &&
            amountOfToken < 10
          ) {
            player.current.audio.current.pause();
            player.current.audio.current.currentTime = 0;
          }
        }}
        listenInterval={1000}
        // other props here
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Alert
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This site wants you to allow autoplay
          </Typography>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onAllow}>Ok</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Player;
