import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateAddress, injected } from "../../../store/actions/thunks";
import { useWeb3React } from "@web3-react/core";
import { doc, setDoc } from "firebase/firestore";

import Player from "../audioPlayer";
import "./index.scss";
import db from "../../../firebase";
import { useMedia } from "../../../utils/use-media";

const AudioList = () => {
  const mobile = useMedia("(max-width: 580px)");
  const { active, account, activate } = useWeb3React();
  const audioState = useSelector((state) => state.audio);
  const { currentAudio } = audioState;
  const hotCollectionState = useSelector((state) => state.hotCollection);
  const { hotCollections } = hotCollectionState;
  const userState = useSelector((state) => state.user);
  const { amountOfToken } = userState;
  const [audios, setAudios] = useState([]);
  const [cPlayIndex, setCPlayIndex] = useState(-1);
  useEffect(() => {
    let auiosTemp = audioState.audioList.sort(function (a, b) {
      return b.totalVotes - a.totalVotes;
    });
    setAudios(auiosTemp);
  }, [audioState]);
  const getBackColor = (rnd, idx) => {
    const rank = idx + 1;
    const color = rank > 0 ? "rgba(110,112,113,1)" : "rgba(0,174,234,1)";
    const linner = `linear-gradient(${color}, transparent`;
    return linner;
  };
  const connect = async () => {
    try {
      await activate(injected);
    } catch (e) {
      console.log(e);
    }
  };
  const selectItem = async (item) => {
    if (
      audioState.active &&
      (hotCollections.data?.length > 0 || amountOfToken >= 10)
    ) {
      let votedNum = 0;
      audioState.audioList.forEach((audio) => {
        let filteredData = audio?.votedItems?.filter(
          (acc) => acc === audioState.address
        );
        votedNum = votedNum + filteredData.length;
      });

      let status = false;
      item?.votedItems?.forEach((votedAccount) => {
        if (votedAccount === audioState.address) {
          status = true;
        }
      });
      let temp = item?.votedItems;
      let totalVotes = item?.totalVotes;
      if (status) {
        totalVotes = item.totalVotes - 1;
        temp = temp.filter(function (ele) {
          return ele !== audioState.address;
        });
      } else if (votedNum > 0) {
        return;
      } else {
        temp =
          item?.votedItems?.length > 0
            ? [...temp, audioState.address]
            : [audioState.address];
        totalVotes = item?.totalVotes + 1;
        // dispatch(updateVote({ type: "increase", id: audioId }));
      }
      onVote(item, temp, totalVotes);
    }
  };
  const onVote = async (item, temp, totalVotes) => {
    await setDoc(doc(db, "audiosData", item.id), {
      ...item,
      votedItems: temp,
      totalVotes: totalVotes,
    });
  };
  const checkVoteState = (item) => {
    let status = false;
    item?.votedItems?.forEach((votedAccount) => {
      if (votedAccount === audioState.address) {
        status = true;
      }
    });
    return status;
  };
  const displayDesktop = () => {
    return (
      <div className="audio-list-body">
        <div className="col" style={{ backgroundImage: getBackColor(0.5, -1) }}>
          <Player
            type={"current-playing"}
            item={currentAudio}
            handlePlayAudio={() => {}}
          />
        </div>
        <div className="audio-list-header template-grid-audio">
          <div className="template-grid-audio">
            <div className="col">Rank </div>
            <div className="col">Votes</div>
            <div className="col">Vote next song</div>
            <div className="col">Audio</div>
          </div>
        </div>
        {audios.map((item, idx) => {
          const rnd = Math.random();
          return (
            <div
              className={"template-grid-audio " + "rank" + idx}
              key={item.id + item.totalVotes}
            >
              <div
                className={"col center"}
                style={{
                  backgroundImage: getBackColor(rnd, idx),
                  color:
                    idx === 0
                      ? "rgba(233,197,117,1)"
                      : idx === 1
                      ? "rgba(220,220,220,1)"
                      : idx === 2
                      ? "rgba(200,119,56,1)"
                      : "white",
                  fontSize: idx < 3 ? "xx-large" : "normal",
                  fontWeight: "bolder",
                }}
              >
                {idx + 1}
              </div>
              <div
                className="col center"
                style={{ backgroundImage: getBackColor(rnd, idx) }}
              >
                {item.totalVotes}
              </div>
              <div
                className="col center"
                style={{ backgroundImage: getBackColor(rnd, idx) }}
              >
                <div className="center">
                  <input
                    type="checkbox"
                    id={"checlist" + item.id}
                    name="checlist"
                    value="Nft"
                    checked={checkVoteState(item)}
                    onChange={() => selectItem(item)}
                  />
                  ({item?.votedItems?.length > 0 ? item?.votedItems?.length : 0}
                  )
                </div>
              </div>
              <div
                key={item.id + item.totalVotes + "div"}
                className="col"
                style={{ backgroundImage: getBackColor(rnd, idx) }}
              >
                <Player
                  key={item.id + item.totalVotes + "player"}
                  isPlay={idx === cPlayIndex ? true : false}
                  type={"common"}
                  item={item}
                  handlePlayAudio={() => {
                    setCPlayIndex(idx);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const mobileDesktop = () => {
    return (
      <div className="audio-list-body" style={{ width: "87vW" }}>
        <div className="col" style={{ backgroundImage: getBackColor(0.5, -1) }}>
          <Player
            type={"current-playing"}
            item={currentAudio}
            handlePlayAudio={() => {}}
          />
        </div>
        <div className="audio-list-header template-grid-audio">
          <div className="template-grid-audio">
            <div className="col">Rank </div>
            <div className="col">Votes</div>
            <div className="col">Vote next song</div>
            <div className="col">Audio</div>
          </div>
        </div>
        {audios.map((item, idx) => {
          const rnd = Math.random();
          return (
            <div
              className={"template-grid-audio " + "rank" + idx}
              key={item.id + item.totalVotes}
            >
              <div
                className={"col center"}
                style={{
                  backgroundImage: getBackColor(rnd, idx),
                  color:
                    idx === 0
                      ? "rgba(233,197,117,1)"
                      : idx === 1
                      ? "rgba(220,220,220,1)"
                      : idx === 2
                      ? "rgba(200,119,56,1)"
                      : "white",
                  fontSize: idx < 3 ? "xx-large" : "normal",
                  fontWeight: "bolder",
                }}
              >
                {idx + 1}
              </div>
              <div
                className="col center"
                style={{ backgroundImage: getBackColor(rnd, idx) }}
              >
                {item.totalVotes}
              </div>
              <div
                className="col center"
                style={{ backgroundImage: getBackColor(rnd, idx) }}
              >
                <div className="center">
                  <input
                    type="checkbox"
                    id={"checlist" + item.id}
                    name="checlist"
                    value="Nft"
                    checked={checkVoteState(item)}
                    onChange={() => selectItem(item)}
                  />
                  ({item?.votedItems?.length > 0 ? item?.votedItems?.length : 0}
                  )
                </div>
              </div>
              <div
                key={item.id + item.totalVotes + "div"}
                className="col"
                style={{ backgroundImage: getBackColor(rnd, idx) }}
              >
                <Player
                  key={item.id + item.totalVotes + "player"}
                  isPlay={idx === cPlayIndex ? true : false}
                  type={"common"}
                  item={item}
                  handlePlayAudio={() => {
                    setCPlayIndex(idx);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="audio-list-container" style={{ padding: mobile ? 0 : 44 }}>
      {mobile ? mobileDesktop() : displayDesktop()}
    </div>
  );
};
export default AudioList;
