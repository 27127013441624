import { combineReducers } from "redux";
import nftReducer from "./nfts";
import hotCollectionsReducer from "./hotCollections";
import authorListReducer from "./authorList";
import filterReducer from "./filters";
import uiReducer from "./ui";
import modalReducer from "./modalReducer";
import lobbyReducer from "./lobbyReducer";
import audioReducer from "./audioList";
import walletReducer from "./walletReducer";
import userReducer from "./userInfo";

export const rootReducer = combineReducers({
  NFT: nftReducer,
  hotCollection: hotCollectionsReducer,
  authors: authorListReducer,
  filters: filterReducer,
  ui: uiReducer,
  modal: modalReducer,
  lobby: lobbyReducer,
  audio: audioReducer,
  wallet: walletReducer,
  user: userReducer
});

const reducers = (state, action) => rootReducer(state, action);

export default reducers;
