import "process";
import keccak256 from "keccak256";
import WalletConnectProvider from "@walletconnect/ethereum-provider";
import detectEthereumProvider from "@metamask/detect-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
const APP_NAME = "My Awesome App";
const APP_LOGO_URL = "https://example.com/logo.png";
const DEFAULT_ETH_JSONRPC_URL =
  "https://mainnet.infura.io/v3/<YOUR_INFURA_API_KEY>";
export const coinbaseWallet = new CoinbaseWalletSDK({
  appName: APP_NAME,
  appLogoUrl: APP_LOGO_URL,
  darkMode: false,
});
// mainnet
const contractAddress = [
  [
    "0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611",
    "0x813583d4080fc035608dfcee0ac1e9f9c8b23bcf",
  ], //ethereum
  [
    "0x5647Fe4281F8F6F01E84BCE775AD4b828A7b8927",
    "0x813583D4080FC035608DfcEE0aC1e9F9c8b23bcF",
  ], //polygon
  [
    "0xBF05279F9Bf1CE69bBFEd670813b7e431142Afa4",
    "0x813583D4080FC035608DfcEE0aC1e9F9c8b23bcF",
  ], //binance
];
const REQUIRED_CHAIN_ID = [
  "0x1", //ethereum
  "0x89", //polygon
  "0x38", //binance
];

// ropsten
// const contractAddress = [
//     ['0x36bf64f3bbf6c0b5483f3a9f5f794bc91b104a06',
//     '0xbCBE4A9c5bbB9Cd69F5746e5748dee91B547F209',],     //ethereum
//     ['0x0f02b40fc0558fd8d6ce4100f06ee1cf897f0da1',
//     '0xfd780bdf4843236c6d0b648acc83c027b821e3c7',]      //avalanche
// ]
// const REQUIRED_CHAIN_ID = [
//     '0x3',      //ethereum
//     '0xa869'    //avalanche
// ];

// 0 - ethereum
// 1 - avalanche
let currentNetwork = 0;

const chainName = ["Ethereum Mainnet", "Avalanche Fuji Testnet"];

const nativeCurrency = ["ETH", "AVAX"];

const rpcUrl = [
  "https://ropsten.infura.io/v3/732d600b40ff49f192fb476b11efc1f3",
  "https://api.avax-test.network/ext/bc/C/rpc",
];

const Contract = {
  token: 0,
  staking: 1,
};

const TokenMethods = {
  balanceOf: 0,
  allowance: 1,
  totalSupply: 2,
  approve: 3,
};

const StakingMethods = {
  balanceOf: 0,
  unclaimedRewardsOf: 1,
  takeStakeFee: 2,
  takeUnstakeFee: 3,
  takeRestakeFee: 4,
  totalStaked: 5,
  numberOfStakeHolders: 6,
  totalClaimedRewardsOf: 7,
  stake: 8,
  unstake: 9,
  restakeRewards: 10,
  claimRewards: 11,
};

const contractMethod = [
  [
    "balanceOf(address)", //0
    "allowance(address,address)", //1
    "totalSupply()", //2
    "approve(address,uint256)", //3
  ],
  [
    "balanceOf(address)", //0
    "unclaimedRewardsOf(address)", //1
    "takeStakeFee()", //2
    "takeUnstakeFee()", //3
    "takeRestakeFee()", //4
    "totalStaked()", //5
    "numberOfStakeHolders()", //6
    "totalClaimedRewardsOf(address)", //7
    "stake(uint256)", //8
    "unstake(uint256)", //9
    "restakeRewards()", //10
    "claimRewards()", //11
  ],
];

let ethereum;
let account;
let isConnected;
let isMetamasked;
let disconnectHandlers = [];
let connectHandlers = [];
let changeCurrentNetworkHandlers = [];
let plePriceHandlers = [];
let plePrice = -1;

async function connectWallet(isMetamask, isCoinbase) {
  isMetamasked = isMetamask;
  if (isCoinbase) {
    ethereum = coinbaseWallet.makeWeb3Provider(
      DEFAULT_ETH_JSONRPC_URL,
      REQUIRED_CHAIN_ID[currentNetwork]
    );
  } else if (isMetamask) {
    if (typeof window === "undefined") {
      return;
    }
    ethereum = await detectEthereumProvider();
  } else {
    ethereum = new WalletConnectProvider({
      rpc: {
        [1]: "https://mainnet.infura.io/v3/732d600b40ff49f192fb476b11efc1f3",
      },
      qrcodeModalOptions: {
        mobileLinks: [
          "rainbow",
          "metamask",
          "argent",
          "trust",
          "imtoken",
          "pillar",
        ],
        desktopLinks: ["encrypted ink"],
      },
    });
    await ethereum.enable().catch((error) => console.log(error));
  }
  await ethereum
    .request({ method: "eth_requestAccounts" })
    .then((selectedAccount) => {
      account = selectedAccount[0];
      isConnected = true;
      ethereum.on("disconnect", disconnectWallet);
      ethereum.on("accountsChanged", (selectedAccount) => {
        account = selectedAccount[0];
        ethereum
          .request({
            method: "eth_unsubscribe",
            params: [account],
          })
          .then()
          .catch((error) => console.log(error));
        if (!!account)
          ethereum
            .request({
              method: "eth_subscribe",
              params: [
                "logs",
                {
                  address: [selectedAccount[0]],
                },
              ],
            })
            .then()
            .catch((error) => console.log(error));
        else disconnectWallet();
      });
      if (isMetamask)
        ethereum
          .request({
            method: "eth_subscribe",
            params: [
              "logs",
              {
                address: [
                  account,
                  contractAddress[currentNetwork][Contract.token],
                  contractAddress[currentNetwork][Contract.staking],
                ],
              },
            ],
          })
          .then()
          .catch((error) => console.log(error));
      // switchChainIdIfNeeded();
      for (var i = 0; i < connectHandlers.length; i++) {
        connectHandlers[i]();
      }
    })
    .catch((error) => console.log(error));
}

function disconnectWallet() {
  console.log("disconnect wallet");
  isConnected = false;
  for (var i = 0; i < disconnectHandlers.length; i++) {
    disconnectHandlers[i]();
  }
}

function addDisconnectHandler(func) {
  disconnectHandlers.push(func);
}

function addConnectHandler(func) {
  connectHandlers.push(func);
}

function addPlePriceHandler(func) {
  plePriceHandlers.push(func);
}

function isOnRightNetwork() {
  return !ethereum || ethereum.chainId === REQUIRED_CHAIN_ID[currentNetwork];
}

function setPlePrice() {
  if (plePrice >= 0) return;
  plePrice = 0;
  var xmlhttp = new XMLHttpRequest();
  var url =
    "https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x3873965e73d9a21f88e645ce40b7db187fde4931&vs_currencies=usd";
  var sendRequestInterval;

  xmlhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      var response = JSON.parse(this.responseText);
      if (!!response["0x3873965e73d9a21f88e645ce40b7db187fde4931"]) {
        plePrice =
          response["0x3873965e73d9a21f88e645ce40b7db187fde4931"]["usd"];
        clearInterval(sendRequestInterval);
        for (var i = 0; i < plePriceHandlers.length; i++) {
          plePriceHandlers[i](plePrice);
        }
      }
    }
  };

  sendRequestInterval = setInterval(function () {
    xmlhttp.open("GET", url, true);
    xmlhttp.send();
  }, 1000);
}

function getPlePrice() {
  return plePrice;
}

async function fetchEthereum() {
  return isConnected ? ethereum : false;
}

async function fetchAccount() {
  return isConnected ? account : false;
}

function addChangeCurrentNetworkHandler(func) {
  changeCurrentNetworkHandlers.push(func);
}

function setCurrentNetwork(network) {
  for (var i = 0; i < changeCurrentNetworkHandlers.length; i++) {
    changeCurrentNetworkHandlers[i](network);
  }
  currentNetwork = network;
  if (isConnected == false) return;
  switchChainIdIfNeeded();
  for (var i = 0; i < connectHandlers.length; i++) {
    connectHandlers[i]();
  }
}

let tokenMethods = {
  balanceOf: async (callback) => {
    await call(
      false,
      Contract.token,
      TokenMethods.balanceOf,
      callback,
      !!account ? account.substring(2) : false
    );
  },
  allowance: async (callback) => {
    await call(
      false,
      Contract.token,
      TokenMethods.allowance,
      callback,
      !!account ? account.substring(2) : false,
      contractAddress[currentNetwork][Contract.staking].substring(2)
    );
  },
  totalSupply: async (callback) => {
    await call(false, Contract.token, TokenMethods.totalSupply, callback);
  },
  approveAll: async () => {
    await call(
      true,
      Contract.token,
      TokenMethods.approve,
      () => {},
      contractAddress[currentNetwork][Contract.staking].substring(2),
      "ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    );
  },
};

let stakingMethods = {
  balanceOf: async (callback) => {
    await call(
      false,
      Contract.staking,
      StakingMethods.balanceOf,
      callback,
      !!account ? account.substring(2) : false
    );
  },
  unclaimedRewardsOf: async (callback) => {
    await call(
      false,
      Contract.staking,
      StakingMethods.unclaimedRewardsOf,
      callback,
      !!account ? account.substring(2) : false
    );
  },
  takeStakeFee: async (callback) => {
    await call(false, Contract.staking, StakingMethods.takeStakeFee, callback);
  },
  takeUnstakeFee: async (callback) => {
    await call(
      false,
      Contract.staking,
      StakingMethods.takeUnstakeFee,
      callback
    );
  },
  takeRestakeFee: async (callback) => {
    await call(
      false,
      Contract.staking,
      StakingMethods.takeRestakeFee,
      callback
    );
  },
  totalStaked: async (callback) => {
    await call(false, Contract.staking, StakingMethods.totalStaked, callback);
  },
  numberOfStakeHolders: async (callback) => {
    await call(
      false,
      Contract.staking,
      StakingMethods.numberOfStakeHolders,
      callback
    );
  },
  totalClaimedRewardsOf: async (callback) => {
    await call(
      false,
      Contract.staking,
      StakingMethods.totalClaimedRewardsOf,
      callback,
      !!account ? account.substring(2) : false
    );
  },
  stake: async (amount) => {
    await call(true, Contract.staking, StakingMethods.stake, () => {}, amount);
  },
  unstake: async (amount) => {
    await call(
      true,
      Contract.staking,
      StakingMethods.unstake,
      () => {},
      amount
    );
  },
  restakeRewards: async () => {
    await call(true, Contract.staking, StakingMethods.restakeRewards, () => {});
  },
  claimRewards: async () => {
    await call(true, Contract.staking, StakingMethods.claimRewards, () => {});
  },
};

async function switchChainIdIfNeeded(callOnRightChain = undefined) {
  const requiredChainId = REQUIRED_CHAIN_ID[currentNetwork];
  console.log("currentNetwork-", currentNetwork);
  console.log("requiredChainId", requiredChainId);
  console.log("here");
  if (!ethereum || !account) return;
  if (ethereum.chainId != requiredChainId) {
    ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: REQUIRED_CHAIN_ID[currentNetwork] }],
      })
      .then()
      .catch((error) => {
        if (error.code === 4902) {
          ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: REQUIRED_CHAIN_ID[currentNetwork],
                chainName: chainName[currentNetwork],
                nativeCurrency: {
                  name: nativeCurrency[currentNetwork],
                  symbol: nativeCurrency[currentNetwork],
                  decimals: 18,
                },
                rpcUrls: [rpcUrl[currentNetwork]],
              },
            ],
          });
        } else {
          console.error(error);
        }
      });
  } else if (callOnRightChain !== undefined) {
    callOnRightChain();
  }
}

async function call(isWrite, contract, method, callback, ...args) {
  console.log("currentNetwork-", currentNetwork);
  console.log("account-", account);
  console.log(
    " contractAddress[currentNetwork][contract],-",
    contractAddress[currentNetwork][contract]
  );
  if (!isConnected) {
    callback(0);
    return;
  }
  let callData =
    "0x" +
    keccak256(contractMethod[contract][method]).toString("hex").substring(0, 8);
  args.map((element) => {
    if (!!element) callData += element.toString().padStart(64, "0");
  });

  if (isWrite === false) {
    let params = [];
    params.push({
      from: account,
      to: contractAddress[currentNetwork][contract],
      data: callData,
    });
    params.push("latest");
    ethereum
      .request({
        method: "eth_call",
        params: params,
      })
      .then((result) => callback(result))
      .catch((error) => {
        console.log(error);
        callback(0);
      });
  } else {
    let transactionFunc = () =>
      ethereum
        .request({
          method: "eth_estimateGas",
          params: [
            {
              from: account,
              to: contractAddress[currentNetwork][contract],
              data: callData,
            },
          ],
        })
        .then((gasEstimation) =>
          ethereum
            .request({
              method: "eth_sendTransaction",
              params: [
                {
                  from: account,
                  to: contractAddress[currentNetwork][contract],
                  gas: gasEstimation,
                  data: callData,
                },
              ],
            })
            .then((result) => callback(result))
            .catch((error) => console.log(error))
        )
        .catch((error) => console.log(error));
    if (!isMetamasked) transactionFunc();
    else switchChainIdIfNeeded(transactionFunc);
  }
}

export {
  tokenMethods,
  stakingMethods,
  contractAddress,
  currentNetwork,
  connectWallet,
  disconnectWallet,
  fetchEthereum,
  fetchAccount,
  addDisconnectHandler,
  addConnectHandler,
  setPlePrice,
  getPlePrice,
  addPlePriceHandler,
  setCurrentNetwork,
  isOnRightNetwork,
  switchChainIdIfNeeded,
  addChangeCurrentNetworkHandler,
};
