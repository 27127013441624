import axios from "axios";

export const Axios = axios.create();
export const Canceler = axios.CancelToken.source();

export const OpenSeaClient = axios.create({
  baseURL: "https://api.opensea.io/api/v1",
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "X-API-KEY": "9c4f22d6861f45188465ef86cf03c62b",
    //'Authorization': 'token <your-token-here> -- https://docs.GitHub.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token'
  },
});


export const VaultAPIClient = axios.create({
  baseURL: "https://api.apilayer.com",
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "apikey": "r7cULiWK3K3md4ijxz2CuWdNIqR8zCB9",
    //'Authorization': 'token <your-token-here> -- https://docs.GitHub.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token'
  },
});

