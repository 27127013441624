import React, { useEffect, useState } from "react";
import "./ModalConnectors.scss";

import MetaMaskLogo from "../../../assets/img/wallets/metamask.svg";
import CoinbaseLogo from "../../../assets/img/wallets/coinbase.svg";
import WalletConnectLogo from "../../../assets/img/wallets/walletconnect.svg";
import { setCurrentNetwork } from "../../../browser/web3/meditate";

import Close from "../../../assets/img/icons/close.svg";
// import { walletconnect } from '@/browser/web3/WalletConnectors'
import { useMedia } from "../../../utils/use-media";

const ConnectorNames = {
  Injected: "MetaMask",
  WalletConnect: "WalletConnect",
};

const isMetamask = {
  [ConnectorNames.Injected]: true,
  [ConnectorNames.WalletConnect]: false,
};
const networks = ["Ethereum", "Polygon", "Binance"];

// use deep link for metamask mobile
//   <a href="https://metamask.app.link/dapp/pletori-ui-build.herokuapp.com/" rel="external" title="open in Metamask app"></a>
export default function ModalConnectors(props) {
  const isMobile = useMedia("(max-width: 580px)");
  const logos = {
    MetaMask: <img src={MetaMaskLogo} alt="MetaMaskLogo" />,
    WalletConnect: <img src={WalletConnectLogo} alt="WalletConnectLogo" />,
  };
  const [currentNet, setNetwork] = useState(networks[0]);

  return (
    <div
      className={`ple-wallet-select`}
      style={{ display: `${props.active ? "flex" : " none"}` }}
    >
      <div className="wallets-list">
        <span
          className="icon--close"
          aria-label="close"
          onClick={() => props.close(false)}
        >
          <img
            src={Close}
            style={{
              width: "20px",
              backgroundColor: "transparent",
            }}
            alt="discord"
          />
        </span>
        <div>
          <span className="icon--wallet"></span>
          <h4>Connect Your Wallet</h4>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          {networks.map((net, index) => {
            return (
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value={net}
                    checked={currentNet === net}
                    onChange={() => {
                      setNetwork(net);
                      setCurrentNetwork(index);
                    }}
                    style={{ marginRight: 5, marginLeft: 5 }}
                  />
                  {net.charAt(0).toUpperCase() + net.slice(1)}
                </label>
              </div>
            );
          })}
        </div>

        <ul className="lists">
          {Object.keys(isMetamask).map((name) => {
            if (isMobile && typeof window !== "undefined") {
              const { ethereum } = window;
              if (!!ethereum) {
                if (name === ConnectorNames.WalletConnect) return;
              } else if (name === ConnectorNames.Injected) return;
            }
            return (
              <li
                key={name}
                onClick={() => {
                  props.connectWallet(isMetamask[name], false);
                  props.close();
                }}
              >
                {logos[name]}
              </li>
            );
          })}
          <li
            key={"Wallet"}
            onClick={() => {
              props.connectWallet(false, true);
              props.close();
            }}
          >
            <img src={CoinbaseLogo} alt="CoinbaseLogo" />
          </li>
        </ul>
      </div>
    </div>
  );
}
