const initialState = {
  address: "",
  active: false,
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    // case "SET_ADDRESS":
    //   console.log("pay-", action.payload);
    //   return {
    //     ...state,
    //     address: action.payload.account,
    //     active: action.payload.active,
    //   };
    default:
      return state;
  }
};
export default walletReducer;
