export const UserActionTypes = {
  updateNft: "updateNft",
  updateNameColor: "updateNameColor",
  updateAmountOfToken: "updateAmountOfToken",
};
const initialState = {
  userNft: {},
  nameColor: "black",
  amountOfToken: 0,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.updateNft:
      return {
        ...state,
        userNft: action.payload.nft,
      };
    case UserActionTypes.updateNameColor:
      return {
        ...state,
        nameColor: action.payload.nameColor,
      };
    case UserActionTypes.updateAmountOfToken:
      return {
        ...state,
        amountOfToken: action.payload.amountOfToken,
      };
    default:
      return state;
  }
};
export default userReducer;
